/*  ==========================================================================
    BUTTONS
    ========================================================================== */

@mixin switchButtons() {
  display: flex;
  background-color: $clr_white;
  border-radius: 5px;

  > div {
    background-color: #EDEDED;
    border: 1px solid #BEBEBE;
    color: $clr_font_gray_dark;
    padding: 10px 15px;
    opacity: 0.6;
    white-space: nowrap;
    cursor: pointer;

    &:first-child {
      border-radius: 5px 0 0 5px;
    }
    &:last-child {
      border-radius: 0 5px 5px 0;
    }

    &:hover {
      opacity: 0.8;
    }
    &.active {
      opacity: 1;
    }
  }
}