/*  ==========================================================================
    FOOTER
    Styling for the lowest part of the website
    ========================================================================== */

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 30px;
  flex-wrap: wrap;

  a {
    color: inherit;
    text-decoration: none;
  }

  .legal {
    display: flex;
    font-size: 14px;

    .legalmenu {
      a {
        margin-left: 5px;
        padding-left: 5px;
        border-left: 1px solid;
      }
    }
  }
  .bn_link {
    color: $clr_black;
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */