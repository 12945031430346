.excel_export_table {
  overflow: scroll;

  table {
    font-family: 'Arial', sans-serif;
    text-align: center;
    tr {
      td {
        &.bg_filled {
          background-color: $clr_orange_dark;
          color: $clr_font_gray_light;
          font-weight: $fw_bold;
        }
      }

      &:nth-child(2n) {
        td.bg_filled {
          background-color: $clr_orange_light;
        }
      }
      &:nth-child(n+3):nth-child(-n+6) {
        td{
          &.bg_filled {
            color: $clr_white;
          }
        }
      }

      &:nth-child(3) {
        td.bg_filled {
          background-color: $clr_green;
        }
      }
      &:nth-child(4) {
        td.bg_filled {
          background-color: $clr_blue;
        }
      }
      &:nth-child(5) {
        td.bg_filled {
          background-color: $clr_red;
        }
      }
      &:nth-child(6) {
        td.bg_filled {
          background-color: $clr_violet;
        }
      }
    }
  }

}