.filters {
  position: absolute;
  right: 25px;
  top: 40px;
  display: flex;
  justify-content: flex-end;
  font-size: 15px;
  line-height: 1em;
  color: $clr_font_gray_light;

  .filter_box {
    width: 215px;
    border-radius: 5px;
    border: 1px solid #909090;
    padding: 10px 35px 10px 25px;
    display: flex;
    align-items: center;
    position: relative;

    .icon {
      position: absolute;
      height: 100%;
      display: flex;
      align-items: center;
      top: 0;
      right: 10px;
      color: #969696;
    }
    .filter_title {
      font-size: 15px;
      opacity: 0.6;
      margin-right: 10px;
      white-space: nowrap;
    }

    &.filter_country {

      .filter_country_preview {
        white-space: nowrap;
        overflow: hidden;
      }
      .popup_coutry_wrapper {
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 2;
        padding-top: 30px;
        display: none;

        .popup_coutry {
          width: 235px;
          background-color: #FDFDFD;
          border-radius: 6px;
          box-shadow: 0 0 10px 0px #d6d6d6;
          font-weight: $fw_regular;
          color: $clr_font_gray_dark;
          position: relative;

          .country_list {
            padding: 15px 30px 15px 20px;

            .item {
              cursor: pointer;
              padding: 5px 0 5px 25px;
              position: relative;

              &:before {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: 50%;
                border: 1px solid $clr_font_gray_light;
                width: 15px;
                height: 15px;
                border-radius: 3px;
                transform: translateY(-50%);
                opacity: 0.5;
              }
              &:after {
                content: '';
                display: block;
                position: absolute;
                width: 5px;
                height: 10px;
                border-right: 2px solid $clr_font_gray_dark;
                border-bottom: 2px solid $clr_font_gray_dark;
                transform: rotate(45deg);
                left: 5px;
                top: 7px;
              }

              &.disabled {
                opacity: 0.5;

                &:before {
                  opacity: 1;
                }
                &:after {
                  display: none;
                }
              }

              &:nth-child(-n + 5) {
                font-weight: $fw_medium;
              }
              &:nth-child(1),
              &:nth-child(5) {
                margin-bottom: 10px;
                text-transform: capitalize;
              }
              &:hover {
                color: $clr_font_gray_light;
              }
            }
            .all_item.deactivated {
              &:before {
                opacity: 1;
              }
              &:after {
                display: none;
              }
            }
          }
        }
      }

      &:hover {
        .popup_coutry_wrapper {
          display: block;
        }
      }
    }
    &.filter_date {
      margin-left: 25px;
      cursor: pointer;

      .filter_date_input {
        width: 100%;
        -webkit-appearance: none;
        border: 0;
        padding: 0;
        margin: 0;
        background: transparent;
        color: inherit;
      }
    }
  }
}

// date picker plugin
.datepickers-container {
  .datepicker--cell.-current- {
    color: inherit;
    text-decoration: underline;
  }
  .datepicker--cell.-selected-,
  .datepicker--cell.-selected-.-current- {
    background-color: $clr_font_gray_light;
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */
@media (max-width: 767px) {
  .accordeon_boxes .item {
    padding: 0;
  }
}