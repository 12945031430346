/*  ==========================================================================
    VARIABLES
    Collection of all variables

    INFO:
    - try to use variables as much as possible, it makes life easier
    ========================================================================== */

/*
#
# COLORS
#
*/

// main colors
$clr_white: #fff;
$clr_black: #000;
$clr_font_gray_dark: #3E3E3E;
$clr_font_gray_medium: #5C5C5C;
$clr_font_gray_light: #6C6C6C;

$clr_bn_red: #AF1333;

// custom colors
$clr_bg_saturday: rgba(239, 239, 239, 0.25);
$clr_bg_sunday: rgba(243, 243, 243, 0.7);
$clr_table_border: rgba(224, 224, 224, 0.4);
$clr_popup_bg: #EFEFEF;

$clr_green: #7ED15E;
$clr_green_2: #61b24b;
$clr_green_3: #408e35;
$clr_green_4: #1f6a1f;

$clr_blue: #449FEB;
$clr_blue_2: #3986c8;
$clr_blue_3: #2d6ba1;
$clr_blue_4: #215079;

$clr_red: #EA4949;
$clr_red_2: #cb3e3e;
$clr_red_3: #a63131;
$clr_red_4: #832525;

$clr_violet: #BA7DCE;
$clr_violet_2: #a46eb6;
$clr_violet_3: #8b5d9b;
$clr_violet_4: #724c80;

$clr_orange_dark: #F79E2D;
$clr_orange_light: #FCD699;


/*
#
# FONTS
#
*/

// font families
$ff_roboto: 'Roboto', sans-serif;

// font weights
$fw_light: 300;
$fw_regular: 400;
$fw_medium: 500;
$fw_semiBold: 600;
$fw_bold: 700;